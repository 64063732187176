import { fabric } from 'fabric';
import * as TWEEN from '@tweenjs/tween.js';
import { Positions } from '../../config/Positions';
import Settings from '../ParseTextSettings/Settings';
import { SvgPainter } from '../../../Converter/SvgPainter';
import { convertSvgToCoordinates, parsePath } from '../../../Converter/utils';
import { MainApp } from '../../MainApp';
import { getBboxGrid } from '../../utils/helpers';
import { Font } from '../../../CanvasText/Font';
import { UNIQUE_SYMBOL } from '../../../../constans';

export interface ParserTextOptions {
  mainApp: MainApp;
  painter: SvgPainter;
}

class ParserWord {
  public tweens: TWEEN.Tween<any>[] = [];

  public marginY = 350;

  public marginX: number = 0;

  public lineTime = 560;

  public painter!: SvgPainter;

  private mainApp: MainApp;

  public countWord = 0;

  public currentWord: string[] = [];

  private countRows = 0;

  public positionId?: number = undefined;

  public translateX: number = 0;

  public translateY: number = 0;

  public currentIdWord: number = 0;

  public currentWord1: string = '';

  public counterWord: number = -1;

  private everyWordLength: number[] = [];

  constructor(options: ParserTextOptions) {
    this.mainApp = options.mainApp;
    if (!this.mainApp.ctx) return;
    this.painter = options.painter;
  }

  public async parseWord(word: string, isAnimate: boolean): Promise<void> {
    this.counterWord += 1;
    const currentWord = word[this.currentIdWord];
    if (this.currentIdWord === 0) this.currentWord1 = word;
    this.currentIdWord += 1;
    const settings = new Settings(currentWord);
    const currentSetting = settings.checkAndReturnSettings();
    const randomValue = this.mainApp.fontSvg ? this.mainApp.fontSvg[this.counterWord] : 1;
    currentSetting?.setFontNumber(randomValue);
    const pathSummary = currentSetting?.getPathSummary();
    const id = currentSetting?.getIdValue();
    if (id) {
      const font = Number(this.mainApp.fontSvg ? this.mainApp.fontSvg[this.countWord] : 1);
      currentSetting?.setFontNumber(font);
      this.countWord += 1;
      await this.chainsLinesInWord(isAnimate, id, pathSummary);
    }
    this.startAnimation(isAnimate);
    this.tweens = [];
  }

  private startAnimation(isAnimate: boolean): void {
    this.tweens.forEach((_, i) => {
      if (!this.tweens[i + 1]) return;
      this.tweens[i].chain(this.tweens[i + 1]);
    });
    this.tweens[0]?.start();
    this.tweens[this.tweens.length - 1]?.onComplete(() => {
      if (this.currentIdWord < this.currentWord1.length) {
        this.parseWord(this.currentWord1, isAnimate);
      } else {
        this.currentWord1 = '';
        this.currentIdWord = 0;
        document.dispatchEvent(new CustomEvent('finishWord'));
      }
    });
  }

  public setRandomPosition(): number {
    return this.mainApp.positionData
      ? this.mainApp.positionData
      : fabric.util.getRandomInt(0, Positions.length - 1);
  }

  public translateWord(index: number, glyphName: string, path: string[]): void {
    this.painter.setScale();
    if (index === 0) {
      this.translateX = this.mainApp.translateX + this.marginX;
      this.translateY = this.mainApp.translateY;
      this.painter.translateGlyph(glyphName);
    }
    this.painter.changeFontsParams(this.mainApp.fontSettings);
    const points = parsePath(path[index]);
    this.painter.setPoints(points);
    const additionalMargin = glyphName === 'endash' ? 30 : 0;
    this.painter.translate.x = this.translateX + additionalMargin;
    this.painter.translate.y = this.translateY;
  }

  public async chainsLinesInWord(isAnimate: boolean, id: string, path?: string[], index = 0) {
    if (path && path[index]) {
      this.translateWord(index, id, path);
      const tween = await this.painter?.drawGlyph(isAnimate, id, index);
      if (tween) {
        this.tweens.push(tween);
        await this.chainsLinesInWord(isAnimate, id, path, index + 1);
      }
    }
  }

  public countDelayWord(prev: string): number {
    const { linePath, polylinePath } = convertSvgToCoordinates(prev);
    const pathSummary = [linePath, polylinePath].flat(1);

    return this.lineTime * pathSummary.length;
  }

  public checkCapacityWithDash(wordList: string[]): string[] {
    const exitPhrase: string[] = [];

    wordList.forEach((item) => {
      const newItem = item.replace(/[й\n]/g, '');
      this.everyWordLength.push(newItem.length);
    });

    for (let wordIndex = 0; wordIndex < wordList.length; wordIndex++) {
      const currentWord = wordList[wordIndex];
      if (this.positionId === undefined) {
        this.positionId = this.setRandomPosition();
      }
      const rightPositionId = [2, 4, 6, 8].find((_) => _ === this.positionId);
      let bool = false;
      if (wordIndex === 0 && rightPositionId && exitPhrase.length < 1) {
        const dashIndex = currentWord.indexOf(UNIQUE_SYMBOL);
        if ((dashIndex > -1 && dashIndex < 2) || currentWord.length < 3) bool = true;
        else this.positionId -= 1;
      }
      let word = '';
      for (let letterIndex = 0; letterIndex < currentWord.length; letterIndex++) {
        const currentLetter = currentWord[letterIndex];
        const previewWordWithCurrentLetter = word + currentLetter;
        const isFitPreviewWordWithCurrentLetter = this.checkCapacity(previewWordWithCurrentLetter.replace(/[й\n]/g, ''), wordIndex, bool);
        if (!isFitPreviewWordWithCurrentLetter) {
          const lastIndex = word.lastIndexOf(UNIQUE_SYMBOL);
          if (lastIndex >= 0) {
            const newWord = `${word.slice(0, lastIndex).replace(/[й\n]/g, '')}-`;
            const isFitWord = this.checkCapacity(newWord, wordIndex, bool);
            const indexSecond = word.lastIndexOf(UNIQUE_SYMBOL || '-', lastIndex - 1);
            const secondLastIndex = indexSecond > -1 ? indexSecond : word.lastIndexOf('-', lastIndex - 1);
            if (!isFitWord) {
              const currentIndex = secondLastIndex >= 0 ? secondLastIndex : lastIndex;
              const editWord = `${word.slice(0, currentIndex).replace(/[й\n]/g, '')}-`;
              exitPhrase.push(editWord);
              const lastSyllablesWord = previewWordWithCurrentLetter.slice(currentIndex).replace(/[й\n]/, '');
              if (letterIndex === currentWord.length - 1) {
                exitPhrase.push(lastSyllablesWord.replace(/[й\n]/, ''));
              } else word = lastSyllablesWord;
              continue;
            } else {
              const wordWrapRest = previewWordWithCurrentLetter.slice(lastIndex).replace(/[й\n]/, '');
              exitPhrase.push(newWord);
              if (letterIndex === currentWord.length - 1) exitPhrase.push(wordWrapRest);
              else word = wordWrapRest;
            }
          } else {
            const arrayWords = this.splitWord(previewWordWithCurrentLetter, wordIndex, bool);

            arrayWords.forEach((item, index) => {
              if (index !== arrayWords.length - 1) {
                exitPhrase.push(item);
              }
            });

            word = arrayWords[arrayWords.length - 1];
            if (letterIndex === currentWord.length - 1) {
              exitPhrase.push(word);
            }
          }
          continue;
        }

        if (letterIndex === currentWord.length - 1) {
          const currentWord = word === previewWordWithCurrentLetter ? word : previewWordWithCurrentLetter;
          const pushWord = currentWord.replace(/[й\n]/g, '');
          exitPhrase.push(pushWord);
        }
        word += currentWord[letterIndex];
        if (exitPhrase.length > 0) bool = false;
      }
    }
    return exitPhrase;
  }

  protected splitWord(word: string, wordIndex: number, isBool: boolean): string[] {
    const { width } = getBboxGrid();
    const accessesWidth = this.mainApp.translateX === 0 && isBool ? width - 60 : 1450 - this.mainApp.translateX - this.marginX;

    const resultWord: string[] = [];
    let newWord = word[0];
    for (let i = 1; i < word.length; i++) {
      const letter = word[i];
      const isFit = this.countWidthWord(newWord, wordIndex) < accessesWidth;
      if (isFit && letter !== UNIQUE_SYMBOL) newWord += letter;
      else if (!isFit) {
        resultWord.push(`${newWord}-`);
        newWord = letter;
      }

      if (i === word.length - 1) resultWord.push(newWord);
    }

    return resultWord;
  }

  protected countWidthWord(word: string, wordIndex: number): number {
    const newWord: string[] = [];

    const font = new Font({
      fontSize: this.mainApp.fontSettings.fontSize,
      buffer: this.mainApp.FontBuffer,
      letterSpacing: this.mainApp.fontSettings.letterSpacing,
    });

    for (let i = 0; i < word.length; i++) {
      const settings = new Settings(word[i]);
      const currentSetting = settings.checkAndReturnSettings();
      if (this.mainApp.fontSvg) {
        const everyWordLengthId = wordIndex - 1;
        const idx = everyWordLengthId < 0 ? 0 : everyWordLengthId;
        const length = wordIndex === 0 ? 0 : this.everyWordLength[idx];
        const randomValue = this.mainApp.fontSvg ? this.mainApp.fontSvg[length + i] : 1;
        currentSetting?.setFontNumber(randomValue);
      }
      const id = currentSetting?.getIdValue();
      if (!id) return 0;
      newWord.push(id);
    }

    return font.countTextWidth(
      newWord,
      this.mainApp.brushSettings.width ?? 15,
      undefined,
      this.mainApp.parserText.kerning,
    ) + 60;
  }

  public checkCapacity(word: string, wordIndex: number, isBool = true): boolean {
    const { width } = getBboxGrid();

    const accessesWidth = this.mainApp.translateX === 0 && isBool ? width - 90 : 2230 - this.mainApp.translateX - this.marginX;

    const widthText = this.countWidthWord(word, wordIndex);
    return widthText < accessesWidth;
  }

  public translateSentence(wordId: number): void {
    if (this.positionId === undefined) return;
    if (wordId === 0) {
      const position = Positions[this.positionId];
      this.marginX = position.x;
      this.mainApp.translateY = position.y;
    } else {
      this.countRows += 1;
      const translate = (this.mainApp.translateY + this.marginY) + this.mainApp.parserText.spacing;
      this.mainApp.ctx?.setTransform(1, 0, 0, 1, 0, 0);
      this.mainApp.ctx?.scale(this.mainApp.scale, this.mainApp.scale);
      this.mainApp.translateY = translate;
      this.mainApp.translateX = 0;
      this.marginX = 124;

      if (this.mainApp.translateY > 3100) {
        this.mainApp.ctx?.setTransform(1, 0, 0, 1, 0, 0);
        this.mainApp.ctx?.scale(this.mainApp.scale, this.mainApp.scale);
        this.mainApp.translateY = 416.8;
        this.mainApp.translateX = 0;
        this.marginX = 124;
      }
    }
  }

  public destroy(): void {
    this.marginX = 0;
    this.mainApp.translateX = 0;
    this.tweens = [];
    this.mainApp.translateY = 0;
    this.currentWord = [];
  }
}

export default ParserWord;
