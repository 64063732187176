import {
  FC, useContext, useEffect, useMemo, useRef, useState,
} from 'react';
import { ReactSVG } from 'react-svg';
// @ts-ignore
// eslint-disable-next-line import/no-extraneous-dependencies,import/no-unresolved
import seedrandom from 'seedrandom-rn';
import createHyphenator from 'hyphen';
import patterns from 'hyphen/patterns/en-us';
import classNames from './Canvas.module.css';
import font from '../../assets/font.svg';
import font1 from '../../assets/font1.svg';
import font2 from '../../assets/font2.svg';
import font3 from '../../assets/font3.svg';
import font4 from '../../assets/font4.svg';
import symbols from '../../assets/symbols.svg';
import font5 from '../../assets/font5.svg';
import numbers from '../../assets/numbers.svg';
import { MainApp } from '../../modules/MainApp/MainApp';
import { ConfigProviderContext } from '../../context/ConfigProvider';
import { ApiType } from '../../types/apiType';
import PhraseCenterBuilder from '../../modules/MainApp/feature/Parser/PhraseCenterBuilder';
import { DefaultBrushSettings } from '../../modules/MainApp/config/DefaultBrushSettings';
import { DefaultFontSettings } from '../../modules/MainApp/config/DefaultFontSettings';
import randomMinusPlusRange from '../../modules/BrushTools/utils/randomMinusPlusRange';
import { UNIQUE_SYMBOL } from '../../constans';
import { removeSpaceBeforeSymbol } from '../../removeSpaceSymbol';
import { foundEqualDashForText } from '../../helpers/foundEqualDashForText';

const Canvas: FC = () => {
  const [mainApp, setMainApp] = useState<MainApp | null>(null);
  const { config, data, setData } = useContext(ConfigProviderContext);
  const [isLoad, setIsLoad] = useState<boolean>(false);
  const [apiUrl, setApiUrl] = useState<string>();

  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!config) {
      return;
    }
    if (data) {
      setTimeout(() => {
        const isFarm = process.env.REACT_APP_IS_FARM === 'true';
        if (wrapperRef.current) {
          const { width, height } = window.getComputedStyle(wrapperRef.current);
          const splitWidth = width.replace('px', '');
          const splitHeight = height.replace('px', '');
          const mainApp = new MainApp({
            svgId: 'svg',
            isDebug: false,
            config,
            isFarm: Boolean(process.env.REACT_APP_IS_FARM) || false,
            background: data.background,
            disableBackground: data.disableBackground ?? false,
            backgroundColor: data.backgroundColor ?? '#000000',
            width: !isFarm ? Number(splitWidth) : undefined,
            height: !isFarm ? Number(splitHeight) : undefined,
          });
          setMainApp(mainApp);
        }
      }, 1000);
    }
  }, [config, data, wrapperRef.current]);

  const getApi = async (url: string): Promise<ApiType> => {
    const result = await fetch(url, {
      method: 'GET',
    });
    // eslint-disable-next-line no-return-await
    return await result.json();
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const tokenParam = urlParams.get('token_id');
    const contractParam = urlParams.get('contract_address');
    const inputData = urlParams.get('input_data');
    const isFarm = process.env.REACT_APP_IS_FARM === 'true';
    let currentApiUrl;

    if (inputData) currentApiUrl = inputData;
    else currentApiUrl = isFarm ? process.env.REACT_APP_FARM_ENDPOINT : process.env.REACT_APP_WEB_ENDPOINT;

    const apiUrl = currentApiUrl ? `${currentApiUrl}?contract_address=${contractParam}&token_id=${tokenParam}` : '/server.json';
    setApiUrl(apiUrl);
    getApi(apiUrl)
      .then((json) => {
        setData(json);
      })
      .catch((error) => {
        throw new Error(error);
      });
  }, []);

  useEffect(() => {
    // @ts-ignore
    window.isAnimated = false;
    // @ts-ignore
    document.isAnimated = false;
    window.addEventListener('isLoad', () => {
      setIsLoad(true);
    });
  }, [mainApp]);

  useEffect(() => {
    window.addEventListener('isAnimated', () => {
      if (apiUrl) {
        // @ts-ignore
        window.isAnimated = true;
        // @ts-ignore
        document.isAnimated = true;
      }
    });
  }, [apiUrl]);

  const countBluring = (length: number): number => (30 - (1 / length) * 30);

  useEffect(() => {
    if (mainApp && data && isLoad) {
      seedrandom(data.seed, { global: true });
      const hyphenateSync = createHyphenator(patterns);
      const editPhrase = removeSpaceBeforeSymbol(data.phrase);
      const word = hyphenateSync(editPhrase, { hyphenChar: UNIQUE_SYMBOL });
      if (typeof word !== 'string') return;
      mainApp?.parserText.destroy();
      mainApp.lineTime = data.animationTime ?? 60000;
      const seedPosition = seedrandom(data.position_seed);
      mainApp.positionData = Math.floor(seedPosition() * 10);
      const builderCentering = new PhraseCenterBuilder({ mainApp });

      const newWordArray = word.toLowerCase().replace(/\n/g, ' /n ');
      const wordArray = newWordArray.split(' ');
      const wordArray2 = wordArray.filter((item) => item.length > 0);

      const arrSvgFont = [];
      for (let i = 0; i < wordArray2.length; i++) {
        for (let j = 0; j < wordArray2[i].length; j++) {
          if (wordArray2[i][j] !== UNIQUE_SYMBOL) {
            if (wordArray2[i] !== '/n') {
              if (Number(wordArray2[i][j]) || Number(wordArray2[i][j]) === 0) {
                const randomValue = randomMinusPlusRange(1, 2);
                arrSvgFont.push(randomValue);
              } else {
                const randomValue = randomMinusPlusRange(0, 5);
                arrSvgFont.push(randomValue);
              }
            }
          }
        }
      }
      mainApp.fontSvg = !data.fontSvgArray ? arrSvgFont : data.fontSvgArray;

      const effects = config?.effects;
      if (effects) {
        const randomEffect = data.effect ? data.effect : randomMinusPlusRange(0, 5);
        const effect = effects[randomEffect];
        mainApp.brushSettings = effect.brushSettings || DefaultBrushSettings;
        mainApp.fontSettings = effect.fontSettings || DefaultFontSettings;
      }
      if (data.disableDrips) mainApp.brushSettings.dripAmount = 0;

      mainApp.parserText.kerning = data.kerning;
      mainApp.parserText.spacing = data.spacing;
      mainApp.parserText.countLineTime(wordArray2, data.animationTime ?? 60000);
      if (mainApp.brushSettings) {
        const blur1 = countBluring(editPhrase.length);
        mainApp.setBrushSettings({ ...mainApp.brushSettings, color: data.colorPaint, shadowBlur: Number(blur1) });
        if (data.brushWidth) mainApp.setBrushSettings({ ...mainApp.brushSettings, width: data.brushWidth });
        if (data.brushOpacity) mainApp.setBrushSettings({ ...mainApp.brushSettings, opacity: data.brushOpacity });
        if (data.dripOpacity) mainApp.setBrushSettings({ ...mainApp.brushSettings, dripOpacity: data.dripOpacity });
      }
      mainApp.setBrushSettings({ ...mainApp.brushSettings, blurScale: mainApp.scale });
      mainApp.parserText.isCenter = data.isCenter ?? false;
      mainApp.skipAnimation = data.skipAnimation ?? false;
      if (!data.isAnimate) return;
      if (data.isCenter) builderCentering.witeText(wordArray2);
      else {
        const withoutSlash = wordArray2.filter((item) => !item.includes('/n'));
        const editText = mainApp.parserText.parserWord.checkCapacityWithDash(withoutSlash);
        foundEqualDashForText(editText);
        mainApp.parserText.parsePhrase(editText);
      }
    }
  }, [mainApp, isLoad, data]);

  const styleCanvas = useMemo(() => {
    const isFarm = process.env.REACT_APP_IS_FARM === 'true';
    const style = !isFarm ? classNames.canvas_absolute : classNames.canvas;
    return style;
  }, []);

  const styleWrapper = useMemo(() => {
    const isFarm = process.env.REACT_APP_IS_FARM === 'true';
    const style = !isFarm ? classNames.wrapper : '';
    return style;
  }, []);

  return (
    <div className={classNames.root}>
      <div ref={wrapperRef} className={styleWrapper}>
        <canvas
          className={styleCanvas}
          id="canvas"
          width={2480}
          height={3508}
        />
      </div>
      <div style={{ display: 'none' }}>
        <ReactSVG src={font} />
        <ReactSVG src={font1} />
        <ReactSVG src={font2} />
        <ReactSVG src={font3} />
        <ReactSVG src={font4} />
        <ReactSVG src={font5} />
        <ReactSVG src={symbols} />
        <ReactSVG src={numbers} />
      </div>
    </div>
  );
};

export default Canvas;
