export const foundEqualDashForText = (editText: string[]) => {
  editText.forEach((item, index) => {
    const nextWord = editText[index + 1];
    if (!nextWord) return;
    const currentItemLastLetter = item[item.length - 1];
    const nextItemLastLetter = nextWord[0];
    if (nextItemLastLetter === '-' && currentItemLastLetter === nextItemLastLetter) {
      editText[index + 1] = nextWord.slice(1);
    }
  });

  editText.forEach((item, index) => {
    if (item.length === 0) {
      editText.splice(index, 1);
    }
  });
};
